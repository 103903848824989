import PeButton from '@pe-libs/react-components/build/components/PeButton';
import PeHidden from '@pe-libs/react-components/build/components/PeHidden';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import useRedirect from '#hooks/useRedirect';
import { leadFormOpened } from '#modules/expertDirectory/actions/createLead';
import messages from './messages';
import useStyles from './styles';

const Actions: FC = () => {
  const formatMessage = useIntlMessage();
  const styles = useStyles();
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(leadFormOpened());
  };
  const { urlToLivePage } = useRedirect();

  return (
    <div className={styles.actionsContainer}>
      <PeButton
        className={styles.button}
        variant="contained"
        color="warning"
        size="medium"
        onClick={handleModalOpen}
        title={formatMessage(messages.button)}
        aria-label={`${formatMessage(messages.button)} button`}
      >
        {formatMessage(messages.button)}
      </PeButton>
      <PeHidden mdUp={true}>
        <PeButton
          className={styles.button}
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => window.open(urlToLivePage('home'), '_blank')}
          title={formatMessage(messages.provenexpertForBusiness)}
          aria-label={`${formatMessage(messages.provenexpertForBusiness)} button`}
        >
          {formatMessage(messages.provenexpertForBusiness)}
        </PeButton>
      </PeHidden>
    </div>
  );
};

export default Actions;
