import Grid from '@material-ui/core/Grid';
import PeButton from '@pe-libs/react-components/build/components/PeButton';
import PeFormAutocomplete from '@pe-libs/react-components/build/components/PeFormAutocomplete';
import PeFormDatePicker from '@pe-libs/react-components/build/components/PeFormDatePicker';
import PeFormTextArea from '@pe-libs/react-components/build/components/PeFormTextArea';
import Calendar from '@pe-libs/react-components/build/components/PeIcons/Calendar.svg';
import ButtonContainer from '@pe-libs/react-components/build/components/PeModal/ButtonContainer';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLocale } from '#modules/app/selectors';
import { locationsAutocompleted } from '#modules/expertDirectory/actions';
import { getIndustriesDataForAutocomplete, getLocationsAutocompleteData } from '#modules/expertDirectory/selectors';
import AutoSuggestion from '../AutoSuggestion';
import messages from './messages';
import SideContainer from './SideContainer';
import useStyles from './styles';
import { CreateLeadWizardProps } from './types';

const Step1: CreateLeadWizardProps = ({ formik, validate }) => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const locale = useSelector(getUserLocale) ?? 'de-DE';
  const industriesOptions = useSelector(getIndustriesDataForAutocomplete).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
  const locationsOptions = useSelector(getLocationsAutocompleteData)?.map((item) => ({
    labelId: item.id,
    title: item.name,
    value: item.key,
  }));

  const dispatch = useDispatch();

  const handleInputChange = (data: Record<string, unknown>) => {
    dispatch(locationsAutocompleted(data as { location: string }));
  };

  return (
    <>
      <SideContainer>
        <PeTypography variant="h4" className={styles.title}>
          {formatMessage(messages.step1title)}
        </PeTypography>
        <PeTypography variant="body2" className={styles.text}>
          {formatMessage(messages.step1desc)}
        </PeTypography>
        <PeTypography variant="body1" gutterBottom={true} className={styles.semiBold}>
          {formatMessage(messages.step1formTitle)}
        </PeTypography>
        <Grid container={true} spacing={6}>
          <Grid xs={12} item={true}>
            <div className={styles.autocomplete}>
              <PeFormAutocomplete
                label={formatMessage(messages.step1industryPlaceholder)}
                name="page1.industry"
                options={industriesOptions}
                getOptionSelected={(option, value) => value.value === option.value}
                formik={formik}
                disablePortal={true}
                classes={{ listbox: styles.listBox }}
              />
            </div>
          </Grid>
          <Grid xs={12} md={6} item={true}>
            <AutoSuggestion
              name="page1.location"
              formik={formik}
              label={formatMessage(messages.step1locationPlaceholder)}
              options={locationsOptions ?? []}
              onInputChange={handleInputChange}
            />
          </Grid>
          <Grid xs={12} md={6} item={true} className={styles.datePicker}>
            <PeFormDatePicker
              name="page1.dueDate"
              formik={formik}
              label={formatMessage(messages.step1datePlaceholder)}
              startIcons={{ icon: <Calendar /> }}
              dueDate={true}
              locale={locale as 'en-US' | 'en-GB' | 'de-DE' | 'es-ES' | 'fr-FR' | 'it-IT' | 'pt-PT' | 'nl-NL'}
            />
          </Grid>
          <Grid xs={12} item={true} className={styles.keywords}>
            <PeFormTextArea
              name="page1.message"
              formik={formik}
              label={formatMessage(messages.step1descPlaceholder)}
              maxChars={500}
              rows={5}
            />
          </Grid>
        </Grid>
        <div className={styles.required}>
          <span>{formatMessage(messages.required)}</span>
        </div>
      </SideContainer>
      <ButtonContainer>
        <PeButton
          color="primary"
          data-testid="createLead-form-next"
          variant="contained"
          onClick={validate}
          className={styles.button}
          aria-label={`${formatMessage(messages.next)} button`}
        >
          {formatMessage(messages.next)}
        </PeButton>
      </ButtonContainer>
    </>
  );
};

export default Step1;
