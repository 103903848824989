import { createSelector } from '@reduxjs/toolkit';
import { getUserLocale } from '#modules/app/selectors';
import { StateType } from '../../../types';

export const getTopSearches = createSelector(
  (state: StateType) => state.expertDirectory,
  (expertDirectory) => expertDirectory.topSearches,
);

export const getTopSearchesByLocation = createSelector(getTopSearches, (topSearches) =>
  topSearches.data?.map((item) => item.location),
);

export const getTopSearchesByIndustry = createSelector(getTopSearches, getUserLocale, (topSearches, locale) => {
  const lowerCaseLocale = locale?.toLocaleLowerCase() ?? 'de-de';

  return topSearches.data?.map((item) =>
    (lowerCaseLocale === 'de-de'
      ? (item.industry as { name: { de_DE: string } }).name.de_DE
      : (item.industry as { name: { en_US: string } }).name.en_US),
  );
});
