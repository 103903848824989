import { createUseStyles } from '@pe-libs/react-components/build/theme';
import { textShadow } from '#modules/expertDirectory/components/SearchSection/helper';

const useStyles = createUseStyles(
  'SearchSection',
  ({
    palette: { common, primary, gradient, grey },
    breakpoints: {
      up,
      down,
      values: { lg },
    },
    typography,
  }) => ({
    textShadow: {
      textShadow,
    },
    actionsContainer: {
      padding: '1.5rem 1.5rem 1rem',
      textAlign: 'center',
      [up('md')]: {
        padding: 0,
      },
      '& .MuiButtonBase-root': {
        '& .MuiButton-label': {
          whiteSpace: 'nowrap',
        },
      },
    },
    button: {
      width: '13.78rem',
      marginBottom: '0.5rem',
      [up('md')]: {
        marginBottom: '0',
      },
    },
    background: {
      backgroundPosition: 'top',
      backgroundSize: 'cover',
    },
    pageContainer: {
      maxWidth: `${lg}px`,
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexWrap: 'wrap',
    },
    gradient: {
      color: common.white,
      width: '100%',
      backgroundImage: `linear-gradient(295deg, #35a9a6 20%, ${primary.main})`,
      [down('xs')]: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    imageGradient: {
      composes: '$gradient',
      backgroundImage: gradient.mask.primary,
    },
    section: {
      width: '100%',
      padding: '1rem',
      margin: ' 0 auto',
      maxWidth: `${lg}px`,
      [up('md')]: {
        padding: '0 1.5rem',
      },
    },
    nativeSelect: {
      width: '100%',
      height: '100%',
    },

    searchSection: {
      textAlign: 'center',
      composes: '$textShadow',
      paddingTop: '7rem',
      paddingBottom: '3rem',
      [up('sm')]: {
        paddingTop: '9.5rem',
        paddingBottom: '5.5rem',
      },
    },

    form: {
      maxWidth: '861px',
      margin: 'auto',
    },

    searchDescription: {
      margin: '0.5rem auto 0 0',
      paddingTop: '0!important',
    },
    descriptionText: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: typography.fontWeightRegular,
      letterSpacing: '0.15px',
      marginTop: '0.75rem',
      marginBottom: '0',
      [up('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.875rem',
        letterSpacing: '0.2px',
        marginTop: '0.5rem',
      },
    },
    formContainer: {
      width: '100%',
      margin: '2rem auto 0',
      [up('sm')]: {
        backgroundColor: 'rgba(33, 33, 33, 0.2)',
        borderRadius: '4px',
        padding: '1rem',
      },
    },

    selectRadius: {
      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        height: '47px',
        backgroundColor: grey[200],

        '& .MuiListItemText-root': {
          color: grey[500],
        },

        [up('md')]: {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          '& .MuiOutlinedInput-notchedOutline': {
            borderLeft: 'none',
          },
        },
      },
    },

    unitedInput: {
      '& .MuiOutlinedInput-root': {
        paddingRight: '12px !important',
      },

      [up('sm')]: {
        padding: '0.5rem',
      },
      [up('md')]: {
        '&[data-padding="left"]': {
          paddingLeft: '0',
        },

        '&[data-padding="right"]': {
          paddingRight: '0',
        },
      },
    },

    searchInput: {
      width: '100%',
      [up('sm')]: {
        padding: '0.5rem',
      },

      [down('xs')]: {
        paddingTop: '1rem',
      },
    },

    paddingRight0: {
      [up('sm')]: {
        paddingRight: '0',
      },
    },

    paddingLeft0: {
      [up('sm')]: {
        paddingLeft: '0',
      },
    },
    locationAutocomplete: {
      margin: '0.75rem 0 1rem 0',
      [up('sm')]: {
        margin: '0',
      },
      [up('md')]: {
        marginLeft: '0.5rem',
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRight: 'none',
        },
      },
    },
    cta: {
      '& svg': {
        marginRight: '0.125rem',
      },
    },
    headerWrapper: {
      width: '100%',
      backgroundColor: common.white,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      boxShadow: '0 3px 4px -2px rgba(0, 0, 0, 0.06)',
    },
    mainHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: `${lg}px`,
      width: '100%',
      margin: '0 auto',
      padding: '0.25rem 1.5rem 0.25rem 1rem',
      textShadow: 'none',
    },
    burgerButton: {
      cursor: 'pointer',
      marginLeft: 'auto',
      '&.MuiIconButton-root': {
        padding: '0',
        color: common.white,
        '&:hover': {
          background: 'none',
          color: common.white,
        },
      },
    },
    burgerButtonDark: {
      composes: '$burgerButton',
      '&.MuiIconButton-root': {
        color: grey[700],
        '&:hover': {
          color: grey[700],
        },
      },
    },
    center: {
      margin: 'auto',
    },
    title: {
      paddingBottom: '0!important',
      '& h1': {
        fontSize: '2.125rem',
        lineHeight: '2.5rem',
        letterSpacing: '0.35px',
        [up('sm')]: {
          fontSize: '3rem',
          lineHeight: '3.6rem',
          letterSpacing: '0.4px',
        },
      },
    },
  }),
);

export default useStyles;
