import { defineMessages } from 'react-intl';

const messages = defineMessages({
  searchTitle: { id: 'modules-expertDirectory-bottom-searchTitle' },
  ctaTitle: { id: 'modules-expertDirectory-bottom-ctaTitle' },
  ctaButton: { id: 'modules-expertDirectory-bottom-ctaButton' },
  ctaDescription: { id: 'modules-expertDirectory-bottom-ctaDescription' },
  searchNameLabel: { id: 'modules-expertDirectory-searchNameLabel' },
  searchPostCodeLabel: { id: 'modules-expertDirectory-searchPostCodeLabel' },
  searchButton: { id: 'modules-expertDirectory-searchButton' },
  hoverSearchButton: { id: 'modules-expertDirectory-hoverSearchButton' },
});

export default messages;
