import { createSelector } from '@reduxjs/toolkit';
import { StateType } from '../../../types';

export const getSearchResult = createSelector(
  (state: StateType) => state.expertDirectory,
  (expertDirectory) => expertDirectory.searchResult,
);

export const getSearchResultMeta = createSelector(getSearchResult, (searchResult) => searchResult.meta);

export const getSearchResultData = createSelector(getSearchResult, (searchResult) => searchResult.data);

export const getSearchResultContext = createSelector(getSearchResult, (searchResult) => searchResult.context);

export const getSearchRadius = createSelector(
  (state: StateType) => state.expertDirectory,
  (expertDirectory) => expertDirectory.searchRadius.searchRadius,
);
