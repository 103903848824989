import { defineMessages } from 'react-intl';

const messages = defineMessages({
  seoTitle: { id: 'seo-title', defaultMessage: 'More revenue with customer reviews | ProvenExpert.com' },
  expertDirectoryStartPageSeoTitle: {
    id: 'expert-directory-start-page-seoTitle',
    defaultMessage: 'ExpertCompass: Top Experten, Top Bewertungen',
  },
  expertDirectoryStartPageSeoDescription: {
    id: 'expert-directory-start-page-seoDescription',
    defaultMessage:
      'Welche Versicherungsanbieter sind {year} gut? Welcher Makler ist der richtige? Hier finden Sie Dienstleister in der Nähe, die von Kunden empfohlen werden. ',
  },
  expertDirectoryIndustryPageSeoTitle: {
    id: 'expert-directory-industry-city-page-seoTitle',
    defaultMessage: '{resultsCount} Treffer für {industry} in {location}',
  },
  expertDirectoryIndustryPageNoResultsSeoTitle: {
    id: 'expert-directory-industry-no-results-page-seoTitle',
    defaultMessage: 'Keine Treffer gefunden',
  },
  expertDirectoryIndustryCityPageSeoDescription: {
    id: 'expert-directory-industry-city-page-seoDescription',
    defaultMessage:
      'Die besten Ergebnisse für {industry} {year} in Ihrer Nähe. Kostenlos Angebote von empfohlenen Dienstleistern im ExpertCompass erhalten!',
  },
  expertDirectoryIndustryPageSeoDescription: {
    id: 'expert-directory-industry-page-seoDescription',
    defaultMessage:
      'Wer wird {year} von Kunden empfohlen? Finden Sie im ExpertCompass aus über 14 Mio. Kundenbewertungen den passenden Dienstleister.',
  },

  expertDirectorySearchPageSeoDescription: {
    id: 'expert-directory-search-page-seoDescription',
    defaultMessage:
      'Sie haben {query} in {location} gesucht? Im ExpertCompass finden Sie die {resultsCount} am besten bewerteten Dienstleister für diese Suche. Jetzt Angebote erhalten!',
  },
  expertDirectoryFrequentSearchesPageSeoTitle: {
    id: 'expert-directory-frequent-page-seoTitle',
    defaultMessage: 'ExpertCompass: Top Suchen',
  },
  expertDirectoryFrequentSearchesPageSeoDescription: {
    id: 'expert-directory-frequent-page-seoDescription',
    defaultMessage:
      'Das haben Kunden im {month} {year} im ExpertCompass am häufigsten gesucht. Finden Sie hier gut bewertete Dienstleister in Ihrer Nähe und erhalten Sie Angebote. ',
  },
  expertDirectoryIndustriesPageSeoTitle: {
    id: 'expert-directory-industries-page-seoTitle',
    defaultMessage: 'ExpertCompass: Alle Branchen von A bis Z',
  },
  expertDirectoryIndustriesPageSeoDescription: {
    id: 'expert-directory-industries-page-seoDescription',
    defaultMessage:
      'Apotheke bis Zahnärzte: Schnell & einfach die beliebtesten 5-Sterne-Unternehmen in der Nähe finden. 14 Mio. Kundenbewertungen. Jetzt Angebote erhalten.',
  },
  seoDescription: {
    id: 'seo-description',
    defaultMessage:
      'Gather customer feedback and recommendations. Reviews and ratings from all platforms combined in a single rating seal. More clicks with Google star ratings ★★★★★',
  },
  surveyThankYouPageSeoTitle: {
    id: 'survey-thank-you-page-seoTitle001',
    defaultMessage: 'Vielen Dank für Ihre Bewertung',
  },
  surveyThankYouPageSeoDescription: {
    id: 'survey-thank-you-page-seoDescription001',
    defaultMessage: 'Jetzt weiterempfehlen!',
  },

  month0: {
    id: 'month-january',
    defaultMessage: 'Januar',
  },
  month1: {
    id: 'month-february',
    defaultMessage: 'Februar',
  },
  month2: {
    id: 'month-march',
    defaultMessage: 'März',
  },
  month3: {
    id: 'month-april',
    defaultMessage: 'April',
  },
  month4: {
    id: 'month-may',
    defaultMessage: 'Mai',
  },
  month5: {
    id: 'month-june',
    defaultMessage: 'Juni',
  },
  month6: {
    id: 'month-july',
    defaultMessage: 'Juli',
  },
  month7: {
    id: 'month-august',
    defaultMessage: 'August',
  },
  month8: {
    id: 'month-september',
    defaultMessage: 'September',
  },
  month9: {
    id: 'month-october',
    defaultMessage: 'Oktober',
  },
  month10: {
    id: 'month-november',
    defaultMessage: 'November',
  },
  month11: {
    id: 'month-december',
    defaultMessage: 'Dezember',
  },
});

export default messages;
