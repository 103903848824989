import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PeHidden from '@pe-libs/react-components/build/components/PeHidden';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import classNames from '#utils/classNames';
import { ListItemType } from '../types';
import useStyles from './styles';

type Props = {
  data: ListItemType;
  styles: ReturnType<typeof useStyles>;
};

const Item: FC<Props> = ({ data: { link, text, hover, target, icon, className }, styles }) => {
  const router = useRouter();

  return (
    <PeLink title={hover} className={classNames(styles.link, className ?? '')} href={link} target={target}>
      <ListItem
        selected={router.asPath === link}
        classes={{ root: styles.listItem }}
        button={true}
        disableGutters={true}
        disableRipple={true}
      >
        <>
          <PeHidden mdUp={true}>
            <div className={styles.iconBox}>{icon}</div>
          </PeHidden>
          <ListItemText className={styles.listItemText} primary={text} />
        </>
      </ListItem>
    </PeLink>
  );
};

export default Item;
