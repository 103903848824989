import { createSelector } from '@reduxjs/toolkit';
import { StateType } from '../../../types';

export const getIndustriesDataForAutocomplete = createSelector(
  (state: StateType) => state.expertDirectory.industries,
  (industries) =>
    industries.items.map((item) => ({
      labelId: item.attributes?.name ?? '',
      title: item.attributes?.title ?? '',
      value: item.id ?? '',
    })),
);
