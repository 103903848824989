import PeButton from '@pe-libs/react-components/build/components/PeButton';
import EnvelopeColored from '@pe-libs/react-components/build/components/PeIcons/EnvelopeColored.svg';
import ButtonContainer from '@pe-libs/react-components/build/components/PeModal/ButtonContainer';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC } from 'react';
import messages from './messages';
import SideContainer from './SideContainer';
import useStyles from './styles';

type Props = {
  onCloseBtnClick: () => void;
};

const SuccessPage: FC<Props> = ({ onCloseBtnClick }) => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();

  return (
    <>
      <SideContainer>
        <div className={styles.successPageContainer}>
          <div className={styles.successPage}>
            <div className={styles.iconEnvelope}>
              <EnvelopeColored />
            </div>
            <PeTypography variant="h4" gutterBottom={true}>
              {formatMessage(messages.successPageTitle, { br: <br /> })}
            </PeTypography>
            <PeTypography variant="body1" gutterBottom={true}>
              {formatMessage(messages.successPageDescription)}
            </PeTypography>
          </div>
        </div>
      </SideContainer>

      <ButtonContainer>
        <div className={styles.button}>
          <PeButton
            color="grey"
            variant="contained"
            onClick={onCloseBtnClick}
            aria-label={`${formatMessage(messages.successPageBackToOverview)} button`}
          >
            {formatMessage(messages.successPageBackToOverview)}
          </PeButton>
        </div>
      </ButtonContainer>
    </>
  );
};

export default SuccessPage;
