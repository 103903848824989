import List from '@material-ui/core/List';
import React, { FC } from 'react';
import { ListItemType } from '../types';
import Item from './Item';
import useStyles from './styles';

type Props = {
  data: ListItemType[];
};

const ListComponent: FC<Props> = ({ data }) => {
  const styles = useStyles();

  return (
    <List className={styles.list} component="nav" disablePadding={true}>
      {data.map((item) => (
        <Item key={item.link} data={item} styles={styles} />
      ))}
    </List>
  );
};

export default ListComponent;
