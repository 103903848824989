import { defineMessages } from 'react-intl';

const messages = defineMessages({
  articleTitle: { id: 'modules-expertDirectory-articleTitle' },
  articleDescription: { id: 'modules-expertDirectory-articleDescription' },
  articleCta: { id: 'modules-expertDirectory-articleCta' },
  sectionCategoriesTitle: { id: 'modules-expertDirectory-sectionCategoriesTitle' },
  sectionCategoriesLink: { id: 'modules-expertDirectory-sectionCategoriesLink' },
  urlAllCategories: { id: 'modules-expertDirectory-urlAllCategories' },
  carouselTitle: { id: 'modules-expertDirectory-carouselTitle' },
  linkLabel: { id: 'modules-expertDirectory-linkLabel' },
  fact1: { id: 'modules-expertDirectory-fact1' },
  fact2: { id: 'modules-expertDirectory-fact2-new' },
  fact3: { id: 'modules-expertDirectory-fact3' },
  redirectionCta: { id: 'modules-expertDirectory-redirectionCta' },
  categoryFinancialServices: { id: 'modules-expertDirectory-category-financialServices' },
  categoryInsuranceServices: { id: 'modules-expertDirectory-category-insuranceServices' },
  categoryRealEstate: { id: 'modules-expertDirectory-category-realEstate' },
  categoryLegalServices: { id: 'modules-expertDirectory-category-legalServices' },
  categoryCarSales: { id: 'modules-expertDirectory-category-carSales' },
  categoryMarketing: { id: 'modules-expertDirectory-category-marketing' },
  categoryOnlineMarketing: { id: 'modules-expertDirectory-category-onlineMarketing' },
  categoryECommerce: { id: 'modules-expertDirectory-category-eCommerce' },
  categoryItServices: { id: 'modules-expertDirectory-category-itServices' },
  categoryCoaching: { id: 'modules-expertDirectory-category-coaching' },
  categoryConsulting: { id: 'modules-expertDirectory-category-consulting' },
  categoryBusinessConsulting: { id: 'modules-expertDirectory-category-businessConsulting' },
  categoryEventsAndEntertainment: { id: 'modules-expertDirectory-category-eventsAndEntertainment' },
  categoryPhotography: { id: 'modules-expertDirectory-category-photography' },
  categoryMediaDesign: { id: 'modules-expertDirectory-category-mediaDesign' },
  categoryHandicraft: { id: 'modules-expertDirectory-category-handicraft' },
  categoryConstruction: { id: 'modules-expertDirectory-category-construction' },
  categoryDoctorsAndNaturopaths: { id: 'modules-expertDirectory-category-doctorsAndNaturopaths' },
  categoryTraining: { id: 'modules-expertDirectory-category-training' },
  categoryServices: { id: 'modules-expertDirectory-category-services' },
  article1title: { id: 'modules-expertDirectory-article1-title' },
  article1desc: { id: 'modules-expertDirectory-article1-desc' },
  article1cta: { id: 'modules-expertDirectory-article1-cta' },
  article2title: { id: 'modules-expertDirectory-article2-title' },
  article2desc: { id: 'modules-expertDirectory-article2-desc' },
  article2cta: { id: 'modules-expertDirectory-article2-cta' },
  article3title: { id: 'modules-expertDirectory-article3-title' },
  article3desc: { id: 'modules-expertDirectory-article3-desc' },
  article3cta: { id: 'modules-expertDirectory-article3-cta' },
  industryPageLink: { id: 'modules-expertDirectory-industryPageLink' },
  hoverCategoriesLink: { id: 'modules-expertDirectory-hoverCategoriesLink' },
  hoverModalCta: { id: 'modules-expertDirectory-hoverModalCta' },
  hoverReadMore: { id: 'modules-expertDirectory-hoverReadMore' },
  hoverArticleRegister: { id: 'modules-expertDirectory-hoverArticleRegister' },
  hoverLaptop: { id: 'modules-expertDirectory-hoverLaptop' },
});

export default messages;
