import { defineMessages } from 'react-intl';

const messages = defineMessages({
  languageGerman: { id: 'modules-auth-contentLayout-languageGerman' },
  languageEnglishUs: { id: 'modules-auth-contentLayout-languageEnglishUs' },
  languageEnglishGb: { id: 'modules-auth-contentLayout-languageEnglishGb' },
  languageSpanish: { id: 'modules-auth-contentLayout-languageSpanish' },
  languageFrench: { id: 'modules-auth-contentLayout-languageFrench' },
  languageItalian: { id: 'modules-auth-contentLayout-languageItalian' },
  languagePortuguese: { id: 'modules-auth-contentLayout-languagePortuguese' },
  languageDutch: { id: 'modules-auth-contentLayout-languageDutch' },
});

export default messages;
