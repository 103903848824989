import ChatBubblesOutlined from '@pe-libs/react-components/build/components/PeIcons/ChatBubblesOutlined.svg';
import FolderOutlined from '@pe-libs/react-components/build/components/PeIcons/FolderOutlined.svg';
import InfoCircleOutlined from '@pe-libs/react-components/build/components/PeIcons/InfoCircleOutlined.svg';
import MagnifyingGlass from '@pe-libs/react-components/build/components/PeIcons/MagnifyingGlass.svg';
import Trophy from '@pe-libs/react-components/build/components/PeIcons/Trophy.svg';
import { convertLocale } from '@pe-libs/react-components/build/lib/helper';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React from 'react';
import { useSelector } from 'react-redux';
import TopBar from '#components/TopBar';
import { getSearchUrl } from '#helpers/searchUrl';
import { getUserLocale } from '#modules/app/selectors/settings';
import Actions from './Actions';
import messages from './messages';
import useStyles from './styles';

const TopMenu = () => {
  const formatMessage = useIntlMessage();
  const styles = useStyles();

  const locale = useSelector(getUserLocale) ?? 'de-de';
  const localeLowerCase = convertLocale(locale, 'lowercaseLocale');

  const menuLinks = [
    {
      text: formatMessage(messages.searchPage),
      link: formatMessage(messages.searchPageLink),
      hover: formatMessage(messages.hoverExpertSearch),
      icon: <MagnifyingGlass />,
    },
    {
      text: formatMessage(messages.industryPage),
      link: formatMessage(messages.industryPageLink),
      hover: formatMessage(messages.hoverBusinessDirectory),
      icon: <FolderOutlined />,
    },
    {
      text: formatMessage(messages.frequentSearchesPage),
      link: formatMessage(messages.frequentSearchesPageLink),
      hover: formatMessage(messages.hoverTopSearches),
      icon: <Trophy />,
    },
    {
      text: formatMessage(messages.blogPage),
      link: `${formatMessage(messages.blogPageLink)}${localeLowerCase}`,
      hover: formatMessage(messages.hoverAdvice),
      icon: <ChatBubblesOutlined />,
      target: '_blank',
    },
    {
      text: formatMessage(messages.homePage),
      link: `${formatMessage(messages.homePageLink)}/${localeLowerCase}`,
      hover: formatMessage(messages.hoverHomePage),
      icon: <InfoCircleOutlined />,
      className: styles.homeMenuLink,
      target: '_blank',
    },
  ];

  return (
    <TopBar
      actionComponent={Actions}
      menuLinks={menuLinks}
      disableLanguageSwitch={true}
      logoLink={`/${localeLowerCase}/${getSearchUrl(localeLowerCase)}/`}
      logoAlt={formatMessage(messages.hoverLogo)}
    />
  );
};

export default TopMenu;
