import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PeButtonIcon from '@pe-libs/react-components/build/components/PeButtonIcon';
import PeHidden from '@pe-libs/react-components/build/components/PeHidden';
import Times from '@pe-libs/react-components/build/components/PeIcons/Times.svg';
import World from '@pe-libs/react-components/build/components/PeIcons/World.svg';
import { useTheme } from '@pe-libs/react-components/build/theme';
import React, { FC } from 'react';
import LanguageSwitch from '#components/languageSwitch';
import Logo from '../Logo';
import { ListItemType } from '../types';
import List from './List';
import useStyles from './styles';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  actionComponent: FC<unknown>;
  menuLinks: ListItemType[];
  disableLanguageSwitch?: boolean;
  logoLink?: string;
  logoAlt?: string;
};

const MainMenu: FC<Props> = ({
  open,
  setOpen,
  actionComponent: Actions,
  menuLinks,
  disableLanguageSwitch,
  logoLink,
  logoAlt = '',
}) => {
  const styles = useStyles();
  const filteredLinks = menuLinks.filter((element) => element.link !== 'https://www.provenexpert.com');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <PeHidden mdUp={true}>
        <SwipeableDrawer
          className={styles.mainMenu}
          anchor="right"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          <div className={styles.mainMenuContainer}>
            <div className={styles.xContainer}>
              <div className={styles.logo}>
                <Logo darkModeColor={true} width={170} height={50} logoLink={logoLink} logoAlt={logoAlt} />
              </div>
              <PeButtonIcon
                className={styles.xIcon}
                size="large"
                onClick={() => setOpen(false)}
                aria-label="close menu button"
              >
                <Times />
              </PeButtonIcon>
            </div>
            <List data={menuLinks} />
            <Actions />
          </div>
        </SwipeableDrawer>
      </PeHidden>
      <PeHidden smDown={true} lgUp={true}>
        <div className={styles.mainMenuContainer}>
          <List data={filteredLinks} />
          <Actions />
        </div>
      </PeHidden>
      <PeHidden mdDown={true}>
        <div className={styles.mainMenuContainer}>
          <List data={menuLinks} />
          {!disableLanguageSwitch && (
            <div className={styles.languageContainer}>
              <LanguageSwitch
                buttonElement={
                  <div className={styles.iconWorld}>
                    <World />
                  </div>
                }
                isHoverable={isDesktop}
              />
            </div>
          )}
          <Actions />
        </div>
      </PeHidden>
    </>
  );
};

export default MainMenu;
