import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles('expert-directory-autosuggest', ({ palette: { grey, error, success, primary } }) => ({
  autocomplete: {
    position: 'relative',
  },
  wrapper: {
    '& .MuiAutocomplete-listbox': {
      width: '100%',
      textShadow: 'none',
    },
    '&.error .MuiInputBase-input': {
      color: error.main,
      '&:focus': {
        color: primary.main,
      },
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      opacity: 1,
    },
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingRight: '65px',
    },
    '& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      paddingRight: '39px',
      paddingLeft: ({ hasPlaceholder }: { hasPlaceholder: boolean }) => (hasPlaceholder ? '20px' : '0'),
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
      right: '9px !important',
    },

    '& .Mui-focused': {
      '& svg': {
        color: primary.main,
      },

      '& .error': {
        '& svg': {
          color: primary.main,
        },
      },
      '& .success': {
        '& svg': {
          color: success.main,
        },
      },
    },
    '& .MuiAutocomplete-input:first-child': {
      paddingLeft: '1rem!important',
    },
  },

  autocompleteIcon: {
    fontSize: '1rem',
    color: grey.A200,
    position: 'absolute',
    zIndex: 2,
    top: '1rem',
    left: ({ hasPlaceholder }: { hasPlaceholder: boolean }) => (hasPlaceholder ? '0.75rem' : 'auto'),
    right: ({ hasPlaceholder }: { hasPlaceholder: boolean }) => (hasPlaceholder ? 'auto' : '0.75rem'),
    '&.error': {
      color: error.main,
    },
    '&.success': {
      color: success.main,
    },
  },
}));

export default useStyles;
