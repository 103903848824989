import Grid from '@material-ui/core/Grid';
import capitalize from '@material-ui/core/utils/capitalize';
import PeButton from '@pe-libs/react-components/build/components/PeButton';
import Location from '@pe-libs/react-components/build/components/PeIcons/Location.svg';
import MagnifyingGlass from '@pe-libs/react-components/build/components/PeIcons/MagnifyingGlass.svg';
import PeInput from '@pe-libs/react-components/build/components/PeInput';
import PeSelect from '@pe-libs/react-components/build/components/PeSelect';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import { isArray } from '@pe-libs/react-components/build/lib/helper';
import usePeFormik from '@pe-libs/react-components/build/lib/hooks/usePeFormik';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { useRouter } from 'next/router';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getKeyword, getLocation } from '#helpers/query';
import { getSearchUrl } from '#helpers/searchUrl';
import { loadingChanged } from '#modules/expertDirectory/actions/expertCompass';
import {
  industriesAutocompleted,
  locationsAutocompleted,
  searchInputCleared,
  setSearchRadius,
} from '#modules/expertDirectory/actions/search';
import { generateQueryFromUrl, generateUrl, resultsUrl } from '#modules/expertDirectory/helpers';
import { searchSchema } from '#modules/expertDirectory/schemas/searchDirectory';
import {
  getIndustriesAutocompleteData,
  getLocationsAutocompleteData,
  getNumberOfCustomerReviews,
  getSearchRadius,
} from '#modules/expertDirectory/selectors';
import classNames from '#utils/classNames';
import AutoSuggestion, { ReasonType } from '../AutoSuggestion';
import selectRadiusItems from './data';
import messages from './messages';
import useStyles from './styles';

export type SearchBarProps = {
  title?: string;
  description?: ReactElement;
  link?: JSX.Element;
  hasSearchField?: boolean;
};

const SearchBar: FC<SearchBarProps> = ({ title, description, link, hasSearchField = true }) => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const router = useRouter();
  const dispatch = useDispatch();
  const search = getKeyword(router.query);
  const location = getLocation(router.query);
  const page = isArray(router.query.page) ? router.query.page[0] : router.query.page;
  const locale = isArray(router.query.lang) ? router.query.lang[0] : router.query.lang;
  const searchRadius = useSelector(getSearchRadius);

  const formik = usePeFormik({
    initialValues: {
      search: generateQueryFromUrl(search),
      location: location && capitalize(location),
      radius: searchRadius,
      page,
      isIndustry: false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      dispatch(loadingChanged(true));

      dispatch(setSearchRadius(formik.values.radius));

      if (values.isIndustry) {
        const url = `/${generateUrl(values.search)}/${values.location ?? ''}${
          values.page ? `?page=${values.page}` : ''
        }`;

        await router.push(`/${locale}/${getSearchUrl(locale)}${url}`);
      } else {
        const url = `?keyword=${generateUrl(values.search)}${values.location ? `&location=${values.location}` : ''}${
          values.page ? `&page=${values.page}` : ''
        }`;

        await router.push(`/${locale}/${getSearchUrl(locale)}/${resultsUrl(locale)}${url}`);
      }

      dispatch(loadingChanged(false));
    },
    validationSchema: searchSchema,
    initialTouched: {
      search: Boolean(search),
      location: Boolean(location),
    },
  });

  const numberOfReviewsInMillions = useSelector(getNumberOfCustomerReviews);

  const autocompleteIndustriesOptions = useSelector(getIndustriesAutocompleteData)?.map((item) => ({
    labelId: item.id,
    title: (item.name as { de_DE: string }).de_DE,
    value: item.key,
  }));

  const autocompleteLocationsOptions = useSelector(getLocationsAutocompleteData)?.map((item) => ({
    labelId: item.id,
    title: item.name,
    value: item.key,
  }));

  const handleIndustriesInputChange = (data: Record<string, string>) => {
    dispatch(industriesAutocompleted(data as { search: string }));
  };

  const handleLocationsInputChange = (data: Record<string, string>) => {
    dispatch(locationsAutocompleted(data as { location: string }));
  };

  const handleIndustriesChange = (data: Record<string, string>, reason: ReasonType) => {
    formik.setFieldValue('isIndustry', reason === 'select-option');
  };

  const handleRadiusInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formik.setFieldValue('radius', event.target.value);
  };

  const handleClear = () => {
    dispatch(searchInputCleared());
  };

  return (
    <div className={classNames(styles.section, styles.searchSection, 'searchSectionModifier')}>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <Grid container={true}>
          <Grid xs={12} sm={12} lg={12} item={true} className={classNames(styles.center, styles.title, 'title')}>
            <PeTypography variant="h1">{title ?? formatMessage(messages.searchTitle)}</PeTypography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            lg={12}
            item={true}
            className={classNames(styles.center, styles.searchDescription, 'description')}
          >
            <p className={classNames(styles.descriptionText, 'descriptionText')}>
              {description ??
                formatMessage(messages.searchDescription, {
                  year: new Date().getFullYear(),
                  reviews: numberOfReviewsInMillions,
                })}
            </p>
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={12} item={true} className={styles.formContainer}>
            <Grid container={true} className={styles.center} justify="center">
              {hasSearchField && (
                <Grid className={styles.searchInput} xs={12} sm={12} md={6} lg={6} item={true}>
                  <AutoSuggestion
                    name="search"
                    formik={formik}
                    placeholder={formatMessage(messages.searchNameLabel)}
                    options={autocompleteIndustriesOptions ?? []}
                    onChange={handleIndustriesChange}
                    onInputChange={handleIndustriesInputChange}
                    onClear={handleClear}
                    variant="secondary"
                    icon={<MagnifyingGlass />}
                    size="medium"
                  />
                </Grid>
              )}
              <Grid xs={12} sm={12} md={3} lg={3} item={true} className={styles.unitedInput} data-padding="right">
                <AutoSuggestion
                  name="location"
                  formik={formik}
                  placeholder={formatMessage(messages.searchPostCodeLabel)}
                  options={autocompleteLocationsOptions ?? []}
                  onInputChange={handleLocationsInputChange}
                  onClear={handleClear}
                  variant="secondary"
                  icon={<Location />}
                  size="medium"
                  className={styles.locationAutocomplete}
                />
              </Grid>
              <Grid xs={12} sm={12} md={3} lg={3} item={true} data-padding="left" className={styles.unitedInput}>
                <PeSelect
                  items={selectRadiusItems}
                  className={styles.selectRadius}
                  input={
                    <PeInput
                      name="input"
                      onChange={handleRadiusInputChange}
                      value={formik.values.radius}
                      variant="secondary"
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container={true} className={styles.center} justify="center">
              <Grid className={styles.searchInput} item={true}>
                <PeButton
                  variant="contained"
                  color="warning"
                  fullWidth={true}
                  size="large"
                  type="submit"
                  data-testid="search-form-submit"
                  className={styles.cta}
                  title={formatMessage(messages.hoverSearchButton)}
                  aria-label={`${formatMessage(messages.hoverSearchButton)} button`}
                >
                  <span role="button" aria-label={`${formatMessage(messages.searchButton)} button`}>
                    {formatMessage(messages.searchButton)}
                  </span>
                </PeButton>
              </Grid>
            </Grid>
          </Grid>
          {link && (
            <Grid xs={12} item={true} className="linkPadding">
              {link}
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default SearchBar;
