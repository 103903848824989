import debounce from '@material-ui/core/utils/debounce';
import PeAutocomplete from '@pe-libs/react-components/build/components/PeAutocomplete';
import { PeAutocompleteOptionsProps } from '@pe-libs/react-components/build/components/PeAutocomplete/types';
import PeFormTextField from '@pe-libs/react-components/build/components/PeFormTextField';
import CheckCircle from '@pe-libs/react-components/build/components/PeIcons/CheckCircle.svg';
import ExclamationCircle from '@pe-libs/react-components/build/components/PeIcons/ExclamationCircle.svg';
import MagnifyingGlass from '@pe-libs/react-components/build/components/PeIcons/MagnifyingGlass.svg';
import getFormikValues from '@pe-libs/react-components/build/lib/formik/values';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { FormikType } from '@pe-libs/react-components/build/lib/types';
import React, { ReactElement, useCallback, useState } from 'react';
import classNames from '#utils/classNames';
import { RadiusValues } from '../SearchSection/data';
import useStyles from './styles';

export type ReasonType = 'create-option' | 'select-option' | 'remove-option' | 'blur' | 'clear';

type FormikPrimary = FormikType<{
  search: string;
  location: string;
  isIndustry: boolean;
  radius?: RadiusValues;
  page?: string;
}>;

type FormikSecondary = FormikType<{
  search: string;
  location: string;
  isIndustry: boolean;
  radius: RadiusValues;
  page: string;
}>;

type Props = {
  options: Array<{ labelId?: string; title?: string; value?: string }>;
  formik: FormikPrimary | FormikSecondary;
  onInputChange: (data: Record<string, string>) => void;
  name: string;
  label?: string;
  placeholder?: string;
  onClear?: () => void;
  handleSubmit?: () => void;
  onChange?: (data: Record<string, string>, reason: ReasonType) => void;
  icon?: ReactElement;
  variant?: 'secondary';
  className?: string;
  size?: 'small' | 'medium';
};

const AutoSuggestion = ({
  options,
  formik,
  label,
  name,
  onClear,
  onChange,
  onInputChange,
  handleSubmit,
  icon,
  placeholder,
  variant,
  className,
  size = 'small',
}: Props) => {
  const formatMessage = useIntlMessage();
  const styles = useStyles({ hasPlaceholder: Boolean(placeholder) });

  const debouncedOnChangeAutocompleted = useCallback(
    debounce((value: string) => {
      onInputChange({ [name]: value });
    }, 500),
    [],
  );

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    selectValue: string | PeAutocompleteOptionsProps | Array<string | PeAutocompleteOptionsProps> | null,
    reason: ReasonType,
  ) => {
    if (!selectValue && onClear) {
      onClear();
    }

    if (onChange) {
      onChange(selectValue as { labelId: string; title: string; value: string }, reason);
    }

    formik.setFieldValue(name, (selectValue as { labelId: string; title: string; value: string })?.title ?? '');
  };

  const handleInputChange = (event: React.ChangeEvent<Record<string, unknown>>, newInputValue: string) => {
    if (!newInputValue && onClear) {
      onClear();
    }

    formik.setFieldValue(name, newInputValue ?? '');

    if (newInputValue.length >= 3) {
      debouncedOnChangeAutocompleted(newInputValue);
    }
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && handleSubmit) {
      formik.submitForm();
    }
  };

  const { isError, isSuccess, value } = getFormikValues(formik, formatMessage, name);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const singleValue = Array.isArray(value) ? value[0] : value;

  const [focused, setFocused] = useState(false);

  const getIcon = (pageName: string) => {
    if (isError) {
      if (pageName === 'page1.location') {
        return <div className={`${styles.autocompleteIcon} error`}>{focused ? null : <ExclamationCircle />}</div>;
      }

      return (
        <div className={`${styles.autocompleteIcon} error`}>
          {focused ? <MagnifyingGlass /> : <ExclamationCircle />}
        </div>
      );
    }

    if (isSuccess) {
      return (
        <div className={`${styles.autocompleteIcon} success`}>
          <CheckCircle />
        </div>
      );
    }

    return icon && <div className={styles.autocompleteIcon}>{icon}</div>;
  };

  return (
    <div className={`${styles.wrapper} ${isError ? 'error' : ''}`}>
      <PeAutocomplete
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyPress={handleOnKeyPress}
        freeSolo={true}
        disableClearable={!onClear}
        size={size}
        disablePortal={true}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        renderInput={({ InputLabelProps, ...props }) => (
          <>
            {getIcon(name)}
            <PeFormTextField
              {...props}
              name={name}
              formik={formik}
              label={label}
              placeholder={placeholder}
              variant={variant}
            />
          </>
        )}
        options={options}
        filterOptions={(filters) => filters}
        className={classNames(styles.autocomplete, className)}
        value={singleValue as string}
        formikValue={singleValue as string}
      />
    </div>
  );
};

export default AutoSuggestion;
