import Grid from '@material-ui/core/Grid';
import PeButton from '@pe-libs/react-components/build/components/PeButton';
import PeHidden from '@pe-libs/react-components/build/components/PeHidden';
import PeImage from '@pe-libs/react-components/build/components/PeImage';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import useIntlNumber from '@pe-libs/react-components/build/lib/intl/hooks/useIntlNumber';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pictureDesktop from '#assets/images/expertDirectory/pictureDesktop.png';
import pictureDesktopMobile from '#assets/images/expertDirectory/pictureDesktopMobile.png';
import useRedirect from '#hooks/useRedirect';
import { getGlobalSystemInfo } from '#modules/app/selectors/globalSystemInfo';
import { leadFormOpened } from '#modules/expertDirectory/actions';
import CreateLead from '#modules/expertDirectory/components/CreateLead';
import { getExternalRatingCrawlerCount } from '#modules/expertDirectory/selectors';
import classNames from '#utils/classNames';
import messages from './messages';
import useStyles from './styles';

const Article: FunctionComponent = () => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const formatNumber = useIntlNumber();
  const dispatch = useDispatch();
  const { urlToLivePage } = useRedirect();
  const handleModalOpen = () => {
    dispatch(leadFormOpened());
  };
  const { query } = useRouter();

  useEffect(() => {
    if (query.openLeadForm) {
      dispatch(leadFormOpened());
    }
  }, [query.openLeadForm]);

  const {
    attributes: { industryTotalCount, profileTotalCount },
  } = useSelector(getGlobalSystemInfo);

  const externalRatingCrawlerCount = useSelector(getExternalRatingCrawlerCount);

  const articleTitle = formatMessage(messages.articleTitle, {
    profilesCount: formatNumber(profileTotalCount as number, { minimumFractionDigits: 0 }),
  });
  const articleDescription = formatMessage(messages.articleDescription, {
    externalPortalsCount: formatNumber(externalRatingCrawlerCount, { minimumFractionDigits: 0 }),
    categoriesCount: formatNumber(industryTotalCount as number, { minimumFractionDigits: 0 }),
  });

  return (
    <div className={classNames(styles.articleSection, 'articleSection')}>
      <div className={styles.pageContainer}>
        <div className={styles.container}>
          <Grid container={true} spacing={6}>
            <Grid xs={12} md={4} item={true} className={classNames(styles.redirectionCta, 'redirectionCta')}>
              <PeButton
                title={formatMessage(messages.hoverModalCta)}
                variant="outlined"
                color="primary"
                onClick={handleModalOpen}
                aria-label={`${formatMessage(messages.redirectionCta)} button`}
              >
                {formatMessage(messages.redirectionCta)}
              </PeButton>
              <CreateLead />
            </Grid>
          </Grid>

          <Grid container={true} spacing={6}>
            <Grid xs={12} md={7} item={true} className={styles.articleContainer}>
              <PeTypography variant="h3" gutterBottom={true}>
                {articleTitle}
              </PeTypography>
              <PeHidden mdUp={true}>
                <Grid xs={12} md={5} item={true} className={styles.imgMobile}>
                  <PeLink target="_blank" href={urlToLivePage('register')}>
                    <PeImage
                      src={pictureDesktopMobile}
                      alt={formatMessage(messages.hoverLaptop)}
                      title={formatMessage(messages.hoverLaptop)}
                      width={500}
                      height={382}
                    />
                  </PeLink>
                </Grid>
              </PeHidden>
              <PeTypography variant="body1" gutterBottom={true} classes={{ root: styles.articleDescription }}>
                {articleDescription}
              </PeTypography>
              <div className={styles.articleButton}>
                <PeButton
                  title={formatMessage(messages.hoverArticleRegister)}
                  onClick={() => window.open(urlToLivePage('register'), '_blank')}
                  color="primary"
                  variant="contained"
                  aria-label={`${formatMessage(messages.hoverArticleRegister)} button`}
                >
                  {formatMessage(messages.articleCta)}
                </PeButton>
              </div>
            </Grid>
            <PeHidden smDown={true}>
              <Grid xs={12} md={5} item={true} className={styles.articleImage}>
                <PeLink target="_blank" href={urlToLivePage('register')}>
                  <PeImage
                    src={pictureDesktop}
                    alt={formatMessage(messages.hoverLaptop)}
                    title={formatMessage(messages.hoverLaptop)}
                    width={507}
                    height={388}
                  />
                </PeLink>
              </Grid>
            </PeHidden>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Article;
