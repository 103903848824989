import { defineMessages } from 'react-intl';

const messages = defineMessages({
  searchButton: { id: 'modules-expertDirectory-searchButton' },
  searchTitle: { id: 'modules-expertDirectory-searchTitle' },
  searchDescription: { id: 'modules-expertDirectory-searchDescription' },
  searchNameLabel: { id: 'modules-expertDirectory-searchNameLabel' },
  searchPostCodeLabel: { id: 'modules-expertDirectory-searchPostCodeLabel' },
  searchCategoryLabel: { id: 'modules-expertDirectory-searchCategoryLabel' },
  hoverLogo: { id: 'modules-expertDirectory-hoverLogo' },
  hoverBurger: { id: 'modules-expertDirectory-hoverBurger' },
  hoverSearchButton: { id: 'modules-expertDirectory-hoverSearchButton' },
  search: { id: 'search' },
  location: { id: 'location' },
  germany: {
    id: 'country-germany',
  },
  inputLabelSearch: { id: 'input-label-search' },
  inputLabelLocation: { id: 'input-label-location' },
  register: { id: 'modules-expertDirectory-mainMenuRegister' },
  login: { id: 'modules-expertDirectory-mainMenuLogin' },
  provenexpertForBusiness: { id: 'modules-expertDirectory-provenexpertForBusiness' },
  provenexpertForBusinessLink: { id: 'modules-expertDirectory-provenexpertForBusinessLink' },
  hoverForBusiness: { id: 'modules-expertDirectory-hoverForBusiness' },
  hoverRegister: { id: 'modules-expertDirectory-hoverRegister' },
  hoverLogin: { id: 'modules-expertDirectory-hoverLogin' },
  button: { id: 'modules-expertDirectory-button' },
});

export default messages;
