export const convertToPhoneLocale = (locale: string) => {
  const partial = locale.split('-');

  return `${partial[0]}-${partial[1]?.toUpperCase()}`;
};

export const convertToIndustryValue = (id?: string) => {
  if (!id) {
    return '';
  }

  return id.startsWith('/2/industries/') ? id : `/v2/industries/${id}`;
};
