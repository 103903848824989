import React, { ReactNode, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { industriesRequested } from '#modules/expertDirectory/actions';
import classNames from '#utils/classNames';
import TopMenu from '../TopMenu';
import SearchBar, { SearchBarProps } from './SearchBar';
import useStyles from './styles';

type SearchSectionProps = SearchBarProps & {
  className?: string;
  bgImage?: string;
  children?: ReactNode;
  hasSearchBar?: boolean;
  hasSearchField?: boolean;
};

const SearchSection: FC<SearchSectionProps> = ({
  className,
  bgImage,
  children,
  hasSearchBar,
  hasSearchField,
  title,
  description,
  link,
}) => {
  const styles = useStyles();
  const gradientStyle = bgImage ? styles.imageGradient : styles.gradient;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(industriesRequested());
  }, []);

  return (
    <div style={{ backgroundImage: bgImage ? `url(${bgImage})` : 'none' }} className={classNames(styles.background)}>
      <div className={classNames(className, gradientStyle)}>
        <div className={classNames(styles.pageContainer)}>
          <TopMenu />
          {hasSearchBar && (
            <SearchBar hasSearchField={hasSearchField} title={title} description={description} link={link} />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
