import { defineMessages } from 'react-intl';

const messages = defineMessages({
  button: { id: 'modules-expertDirectory-button' },
  provenexpertForBusiness: { id: 'modules-expertDirectory-provenexpertForBusiness' },
  searchPage: { id: 'modules-expertDirectory-searchPage' },
  searchPageLink: { id: 'modules-expertDirectory-searchPageLink' },
  hoverExpertSearch: { id: 'modules-expertDirectory-hoverExpertSearch' },
  industryPage: { id: 'modules-expertDirectory-industryPage' },
  frequentSearchesPage: { id: 'modules-expertDirectory-frequentSearchesPage' },
  industryPageLink: { id: 'modules-expertDirectory-industryPageLink' },
  frequentSearchesPageLink: { id: 'modules-expertDirectory-frequentSearchesPageLink' },
  hoverBusinessDirectory: { id: 'modules-expertDirectory-hoverBusinessDirectory' },
  hoverTopSearches: { id: 'modules-expertDirectory-hoverTopSearches' },
  homePage: { id: 'modules-expertDirectory-homePage' },
  homePageLink: { id: 'modules-expertDirectory-homePageLink' },
  blogPage: { id: 'modules-expertDirectory-blogPage' },
  blogPageLink: { id: 'modules-expertDirectory-blogPageLink' },
  hoverHomePage: { id: 'modules-expertDirectory-hoverHomePage' },
  hoverAdvice: { id: 'modules-expertDirectory-hoverAdvice' },
  hoverLogo: { id: 'modules-expertDirectory-hoverLogo' },
});

export default messages;
