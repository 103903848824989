import PeButton from '@pe-libs/react-components/build/components/PeButton';
import Location from '@pe-libs/react-components/build/components/PeIcons/Location.svg';
import MagnifyingGlass from '@pe-libs/react-components/build/components/PeIcons/MagnifyingGlass.svg';
import PeTypography from '@pe-libs/react-components/build/components/PeTypography';
import { isArray } from '@pe-libs/react-components/build/lib/helper';
import usePeFormik from '@pe-libs/react-components/build/lib/hooks/usePeFormik';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchUrl } from '#helpers/searchUrl';
import { leadFormOpened } from '#modules/expertDirectory/actions/createLead';
import { loadingChanged } from '#modules/expertDirectory/actions/expertCompass';
import {
  industriesAutocompleted,
  locationsAutocompleted,
  searchInputCleared,
} from '#modules/expertDirectory/actions/search';
import { generateUrl, resultsUrl } from '#modules/expertDirectory/helpers';
import { searchSchema } from '#modules/expertDirectory/schemas/searchDirectory';
import {
  getIndustriesAutocompleteData,
  getLocationsAutocompleteData,
} from '#modules/expertDirectory/selectors/autocomplete';
import AutoSuggestion, { ReasonType } from '../AutoSuggestion';
import messages from './messages';
import useStyles from './styles';

const BottomSearchSection: React.FC = () => {
  const styles = useStyles();
  const formatMessage = useIntlMessage();
  const router = useRouter();
  const dispatch = useDispatch();
  const locale = isArray(router.query.lang) ? router.query.lang[0] : router.query.lang;
  const formik = usePeFormik({
    initialValues: {
      search: '',
      location: '',
      isIndustry: false,
    },
    onSubmit: async (values) => {
      dispatch(loadingChanged(true));
      const queryUrl = `${resultsUrl(locale)}?keyword=${generateUrl(values.search)}${
        values.location ? `&location=${values.location}` : ''
      }`;
      const industryUrl = `${generateUrl(values.search)}/${values.location ?? ''}`;
      const url = values.isIndustry ? industryUrl : queryUrl;

      formik.resetForm();

      await router.push(`/${locale}/${getSearchUrl(locale)}/${url}`);
      dispatch(loadingChanged(false));
    },
    validationSchema: searchSchema,
    validateOnBlur: false,
  });

  const autocompleteIndustriesOptions = useSelector(getIndustriesAutocompleteData)?.map((item) => ({
    labelId: item.id,
    title: (item.name as { de_DE: string }).de_DE,
    value: item.key,
  }));

  const autocompleteLocationsOptions = useSelector(getLocationsAutocompleteData)?.map((item) => ({
    labelId: item.id,
    title: item.name,
    value: item.key,
  }));

  const handleIndustriesChange = (_data: Record<string, string>, reason: ReasonType) => {
    formik.setFieldValue('isIndustry', reason === 'select-option');
  };

  const handleIndustriesInputChange = (data: Record<string, string>) => {
    dispatch(industriesAutocompleted(data as { search: string }));
  };

  const handleLocationsInputChange = (data: Record<string, string>) => {
    dispatch(locationsAutocompleted(data as { location: string }));
  };

  const handleClear = () => {
    dispatch(searchInputCleared());
  };

  const handleModalOpen = () => {
    dispatch(leadFormOpened());
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.searchContent}>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <PeTypography variant="h4" gutterBottom={true} className={styles.header}>
                {formatMessage(messages.searchTitle)}
              </PeTypography>
              <AutoSuggestion
                name="search"
                formik={formik}
                placeholder={formatMessage(messages.searchNameLabel)}
                options={autocompleteIndustriesOptions ?? []}
                onChange={handleIndustriesChange}
                onInputChange={handleIndustriesInputChange}
                onClear={handleClear}
                variant="secondary"
                icon={<MagnifyingGlass />}
                size="medium"
              />
              <AutoSuggestion
                name="location"
                formik={formik}
                placeholder={formatMessage(messages.searchPostCodeLabel)}
                options={autocompleteLocationsOptions ?? []}
                onInputChange={handleLocationsInputChange}
                onClear={handleClear}
                variant="secondary"
                icon={<Location />}
                size="medium"
                className={styles.locationAutocomplete}
              />
            </div>
            <div>
              <PeButton
                variant="outlined"
                color="white"
                className={styles.button}
                type="submit"
                title={formatMessage(messages.hoverSearchButton)}
                aria-label={`${formatMessage(messages.searchButton)} button`}
              >
                {formatMessage(messages.searchButton)}
              </PeButton>
            </div>
          </form>
        </div>
        <div className={styles.divider} />
        <div className={styles.ctaContent}>
          <div>
            <PeTypography variant="h4" gutterBottom={true} className={styles.header}>
              {formatMessage(messages.ctaTitle)}
            </PeTypography>
            <PeTypography variant="body1" gutterBottom={true}>
              {formatMessage(messages.ctaDescription)}
            </PeTypography>
          </div>
          <div>
            <PeButton
              variant="contained"
              color="warning"
              className={styles.button}
              type="submit"
              onClick={handleModalOpen}
              title={formatMessage(messages.ctaButton)}
              aria-label={`${formatMessage(messages.ctaButton)} button`}
            >
              {formatMessage(messages.ctaButton)}
            </PeButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSearchSection;
