/* eslint-disable camelcase */
import PeModal from '@pe-libs/react-components/build/components/PeModal';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leadCreated, leadFormClosed } from '#modules/expertDirectory/actions';
import { leadChangePageToNext, leadChangePageBack } from '#modules/expertDirectory/actions/createLead';
import { getSearchResultContext } from '#modules/expertDirectory/selectors';
import { getCreateLead } from '#modules/expertDirectory/selectors/createLead';
import { CreateLeadSchema } from '../../schemas/createLead';
import { convertToIndustryValue } from './helper';
import messages from './messages';
import Step1 from './Step1';
import Step2 from './Step2';
import Stepper from './Stepper';
import useStyles from './styles';
import SuccessPage from './SuccessPage';
import { StepProps } from './types';

type Props = StepProps;

const CreateLead: FC<Props> = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const context = useSelector(getSearchResultContext);
  const { replace, asPath, pathname } = useRouter();
  const initialValues = {
    page1: {
      industry: convertToIndustryValue(context?.industry?.id),
      location: context?.location?.name ?? '',
      dueDate: '',
      message: '',
    },
    page2: {
      fullName: '',
      emailAddress: '',
      phoneNumber: '',
      termsOfUse: false,
    },
  };

  const formatMessage = useIntlMessage();

  const { isModalOpen, currentPage } = useSelector(getCreateLead);

  const handleOnSubmit = (values: typeof initialValues) => {
    dispatch(leadCreated(values));
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateLeadSchema,
    onSubmit: (values) => handleOnSubmit(values),
  });

  const handleCloseButton = () => {
    dispatch(leadFormClosed());
    formik.resetForm();
    replace(pathname, asPath.replace('?openLeadForm=true', '').replace('&openLeadForm=true', ''), { shallow: true });
  };

  const validateStep1 = () => {
    (async () => {
      formik.setFieldTouched('page1.industry', true);
      formik.setFieldTouched('page1.location', true);
      formik.setFieldTouched('page1.dueDate', true);
      formik.setFieldTouched('page1.message', true);
      const errors = await formik.validateForm();

      if (!errors.page1) {
        dispatch(leadChangePageToNext());
      }
    })();
  };

  const steps = {
    page1: <Step1 formik={formik} validate={validateStep1} />,
    page2: <Step2 formik={formik} />,
    successPage: <SuccessPage onCloseBtnClick={handleCloseButton} />,
  };

  const handleBack = () => {
    dispatch(leadChangePageBack());
  };

  return (
    <PeModal
      variant="basicModal"
      size="large"
      open={isModalOpen}
      onCloseBtnClick={handleCloseButton}
      backText={['successPage', 'page1'].includes(currentPage) ? undefined : formatMessage(messages.backText)}
      padding={false}
      onBackButtonClick={handleBack}
    >
      <div className={styles.main}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.stepper}>
            <Stepper steps={steps} />
          </div>
          {steps[currentPage]}
        </form>
      </div>
    </PeModal>
  );
};

export default CreateLead;
