import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: { id: 'modules-expertDirectory-createLead-required' },
  step1title: { id: 'modules-expertDirectory-createLead-step1-title' },
  step1desc: { id: 'modules-expertDirectory-createLead-step1-desc' },
  step1formTitle: { id: 'modules-expertDirectory-createLead-step1-formTitle' },
  step1industryPlaceholder: { id: 'modules-expertDirectory-createLead-step1-industryPlaceholder' },
  step1datePlaceholder: { id: 'modules-expertDirectory-createLead-step1-datePlaceholder' },
  step1locationPlaceholder: { id: 'modules-expertDirectory-createLead-step1-locationPlaceholder' },
  step1descPlaceholder: { id: 'modules-expertDirectory-createLead-step1-descPlaceholder' },
  step2namePlaceholder: { id: 'modules-expertDirectory-createLead-step2-namePlaceholder' },
  step2emailPlaceholder: { id: 'modules-expertDirectory-createLead-step2-emailPlaceholder' },
  step2telPlaceholder: { id: 'modules-expertDirectory-createLead-step2-telPlaceholder' },
  step2title: { id: 'modules-expertDirectory-createLead-step2-title' },
  step2desc: { id: 'modules-expertDirectory-createLead-step2-desc' },
  step2formTitle: { id: 'modules-expertDirectory-createLead-step2-formTitle' },
  next: { id: 'modules-expertDirectory-createLead-next' },
  submit: { id: 'modules-expertDirectory-createLead-submit' },
  stepper: { id: 'modules-expertDirectory-createLead-stepper' },
  cancel: { id: 'modules-expertDirectory-createLead-cancel' },
  goBack: { id: 'modules-expertDirectory-createLead-goBack' },
  sendEmailCopy: { id: 'modules-expertDirectory-createLead-sendEmailCopy' },
  termsOfUse: { id: 'modules-expertDirectory-createLead-termsOfUse' },
  termsAndConditionsLinkLabel: { id: 'modules-expertDirectory-createLead-termsAndConditionsLinkLabel' },
  privacyPolicyLinkLabel: { id: 'modules-expertDirectory-createLead-privacyPolicyLinkLabel' },
  telInputFavorites: { id: 'components-peTelInput-favorites' },
  telInputOther: { id: 'components-peTelInput-other' },
  telInputPlaceholder: { id: 'components-peTelInput-placeholder' },
  telInputPlaceholderOpen: { id: 'components-peTelInput-placeholderOpen' },
  telInputPlaceholderOpenForNumber: { id: 'components-peTelInput-placeholderOpenForNumber' },
  successPageTitle: { id: 'modules-expertDirectory-createLead-successPageTitle' },
  successPageDescription: { id: 'modules-expertDirectory-createLead-successPageDescription' },
  successPageBackToOverview: { id: 'modules-expertDirectory-createLead-backToOverview' },
  other: { id: 'select-industry-others' },
  inputLabelEmailAddress: { id: 'input-label-emailAddress' },
  inputLabelIndustry: { id: 'input-label-industry' },
  inputLabelFullName: { id: 'input-label-fullName' },
  inputLabelLocation: { id: 'input-label-location' },
  inputLabelMessage: { id: 'input-label-message' },
  inputLabelDueDate: { id: 'input-label-dueDate' },
  inputLabelPhoneNumber: { id: 'input-label-phoneNumber' },
  inputLabelTrue: { id: 'input-label-true' },
  backText: { id: 'modules-expertDirectory-createLead-backText' },
});

export default messages;
