import imgLogoDarkMode from '@pe-libs/react-components/build/assets/common/logos/logo-darkmode.svg';
import imgLogo from '@pe-libs/react-components/build/assets/common/logos/logo.svg';
import PeImage from '@pe-libs/react-components/build/components/PeImage';
import PeLink from '@pe-libs/react-components/build/components/PeLink';
import React, { FC } from 'react';
import useStyles from './styles';

type Props = {
  logoLink?: string;
  darkModeColor?: boolean;
  width?: number;
  height?: number;
  logoAlt?: string;
};

const Logo: FC<Props> = ({ logoLink = '/', darkModeColor, width = 189, height = 56, logoAlt }) => {
  const styles = useStyles();

  return (
    <PeLink className={styles.logoLink} href={logoLink} title={logoAlt}>
      <div className={styles.logo}>
        <PeImage
          alt={logoAlt}
          src={darkModeColor ? (imgLogoDarkMode as string) : (imgLogo as string)}
          layout="fixed"
          width={width}
          height={height}
        />
      </div>
    </PeLink>
  );
};

export default Logo;
