import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles('SearchSection', ({ breakpoints: { up, between } }) => ({
  actionsContainer: {
    padding: '1.5rem 1.5rem 1rem',
    textAlign: 'center',
    [up('md')]: {
      padding: 0,
    },
    '& .MuiButtonBase-root': {
      '& .MuiButton-label': {
        whiteSpace: 'nowrap',
      },
    },
  },
  homeMenuLink: {
    display: 'flex',
    [between('md', 'md')]: {
      display: 'none',
    },
  },
  button: {
    width: '100%',
    marginBottom: '0.5rem',
    [up('md')]: {
      width: '13.78rem',
      marginBottom: '0',
    },
  },
}));

export default useStyles;
