import { createSelector } from '@reduxjs/toolkit';
import { StateType } from '../../../types';

const getAutocomplete = (state: StateType) => state.expertDirectory.autocomplete;

export const getIndustriesAutocompleteData = createSelector(
  getAutocomplete,
  (autocomplete) => autocomplete.industries.data,
);

export const getIndustriesAutocompleteLoading = createSelector(getAutocomplete, (autocomplete) => autocomplete.loading);

export const getLocationsAutocompleteData = createSelector(
  getAutocomplete,
  (autocomplete) => autocomplete.locations.data,
);
