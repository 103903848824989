import imgLogo from '@pe-libs/react-components/build/assets/common/logos/logo-noclaim.svg';
import PeImage from '@pe-libs/react-components/build/components/PeImage';
import React, { FC } from 'react';
import useStyles from './styles';

const SideContainer: FC = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.main}>
      <div className={styles.sideContainer}>
        <div className={styles.gradientBlue} />
        <div className={styles.logo}>
          <PeImage width={162} height={40} layout="fixed" src={imgLogo as string} alt="ProvenExpert" />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

export default SideContainer;
