import PeChip from '@pe-libs/react-components/build/components/PeChip';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getCreateLead } from '#modules/expertDirectory/selectors/createLead';
import messages from './messages';
import useStyles from './styles';
import { StepProps } from './types';

const PeOnboardingStepper: FC<StepProps> = ({ steps }) => {
  const formatMessage = useIntlMessage();
  const styles = useStyles();
  const { currentPage } = useSelector(getCreateLead);
  const stepsArray = Object.keys(steps ?? {});
  const currentStep = stepsArray.indexOf(currentPage) + 1;

  return (
    <div className={styles.stepContainer}>
      <PeChip
        color="primary"
        label={formatMessage(messages.stepper, { currentStep, steps: stepsArray.length })}
        clickable={false}
      />
    </div>
  );
};

export default PeOnboardingStepper;
