import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles(
  'SearchSection',
  ({
    palette: { common, grey },
    breakpoints: {
      up,
      between,
      values: { lg },
    },
  }) => ({
    headerWrapper: {
      width: '100%',
      backgroundColor: common.white,
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      boxShadow: '0 3px 4px -2px rgba(0, 0, 0, 0.06)',
    },
    burgerButtonDark: {
      composes: '$burgerButton',
      '&.MuiIconButton-root': {
        color: grey[700],
        '&:hover': {
          color: grey[700],
        },
      },
    },
    mainHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: `${lg}px`,
      width: '100%',
      margin: '0 auto',
      padding: '0.25rem 1.5rem 0.25rem 1rem',
      textShadow: 'none',
    },
    logo: {
      height: '3.5rem',
      marginRight: '3rem',
      [between('sm', 1030)]: {
        marginRight: '2rem',
      },
      [up('lg')]: {
        marginRight: '5rem',
      },
    },
    logoLink: {
      display: 'flex',
    },
  }),
);

export default useStyles;
