import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hoverBurger: {
    id: 'main-menu-hoverBurger',
  },
  hoverLogo: { id: 'main-menu-hoverLogo' },
});

export default messages;
