import { createUseStyles } from '@pe-libs/react-components/build/theme';
import { textShadow } from '#modules/expertDirectory/components/SearchSection/helper';

const useStyles = createUseStyles(
  'expert-directory-landing',
  ({
    breakpoints: {
      up,
      down,
      values: { lg },
    },
    palette,
    typography,
  }) => ({
    textShadow: {
      textShadow,
    },
    pageContainer: {
      maxWidth: `${lg}px`,
      width: '100%',
      margin: '0 auto',
      position: 'relative',
    },
    container: {
      padding: '2.625rem 1rem',
      width: '100%',
    },
    searchSection: {
      paddingBottom: '155px',
      [up('sm')]: {
        paddingBottom: '7rem',
      },
      '@media(min-width: 600px) and (max-width: 667px)': {
        paddingBottom: '8.8rem',
      },
      '& .searchSectionModifier': {
        paddingBottom: '3.88rem',
        [up('sm')]: {
          paddingTop: '11rem',
          paddingBottom: '4.06rem',
        },
        [up('md')]: {
          paddingBottom: '4.25rem',
        },
        '& .title': {
          '& h1': {
            fontSize: '2.5rem',
            lineHeight: '3rem',
            letterSpacing: '0.33px',
            [up('md')]: {
              fontSize: '3.75rem',
              lineHeight: '4.5rem',
              letterSpacing: '0.5px',
            },
          },
        },
        '& .descriptionText': {
          marginTop: '1rem',
        },
      },
    },
    section: {
      width: '100%',
      [down('sm')]: {
        padding: '2.625rem 1rem',
      },
    },
    articleSection: {
      background: palette.background.paper,
      paddingTop: '2rem',
      textAlign: 'left',
      [down('xs')]: {
        paddingTop: 0,
        '& h3': {
          fontSize: '1.5rem',
          lineHeight: '1.75rem',
        },
        '& button': {
          width: '100%',
        },
      },
    },
    articleContainer: {
      [up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    articleImage: {
      [up('sm')]: {
        display: 'flex',
        alignItems: 'center',
      },
      '& > a': {
        width: '100%',
      },
    },
    articleDescription: {
      [up('md')]: {
        fontSize: '1.125rem',
        lineHeight: '1.688rem',
      },
    },
    articleButton: {
      marginTop: '2rem',
    },
    articlesSection: {
      composes: '$container',
      paddingTop: '2.625rem',
      paddingBottom: '2.625rem',

      [up('sm')]: {
        paddingTop: '5rem',
        paddingBottom: '5rem',
      },
    },
    center: { textAlign: 'center' },
    articlesTitle: {
      fontSize: '2.125rem',
      fontWeight: typography.fontWeightSemiBold,
      paddingBottom: '2rem',
      [down('sm')]: {
        textAlign: 'left',
      },
      [down('md')]: {
        fontSize: '1.5rem',
        paddingBottom: '1.5rem',
      },
    },
    singleBoxContainer: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      overflow: 'hidden',
      boxShadow: '0 3px 4px -2px rgb(0 0 0 / 6%)',
      userSelect: 'text',
    },
    singleBoxImageContainer: {
      height: '160px',
      overflow: 'hidden',
    },
    singleBoxImage: {
      height: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      transition: 'transform 0.5s',
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.1)',
        transformOrigin: 'center center',
        transition: 'transform 0.5s',
      },
    },
    singleBoxImageFade: {
      opacity: 0.6,
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(286deg, rgba(53, 169, 166, 0.2), rgba(22, 175, 197, 0.69)  95%, rgba(13, 177, 205, 0.91))',
    },
    singleBoxTitleLink: {
      textDecoration: 'none',
      color: palette.grey[800],

      '&:hover': {
        color: palette.primary.main,
        textDecoration: 'none',
      },
    },
    singleBoxTitle: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.25px',
      fontWeight: typography.fontWeightSemiBold,
      textAlign: 'left',
      marginBottom: '1.25rem',

      [up('md')]: {
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
      },
    },
    singleBoxContent: {
      flex: '1 1 auto',
      padding: '1.25rem 1rem 1rem 1rem',
      fontSize: '1rem',
      display: 'flex',
      flexDirection: 'column',
      [up('md')]: {
        minHeight: '21.7rem',
        padding: '1.25rem 1.5rem 1.5rem 1.5rem',
        '& .MuiLink-root': {
          alignSelf: 'flex-start',
        },
      },
    },
    singleBoxDesc: {
      marginBottom: '1.5rem',
    },
    singleBoxButton: {
      display: 'block',
      width: '100%',
      marginTop: 'auto',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    categoriesSection: {
      composes: '$section  $center',
      position: 'absolute',
      zIndex: 1,
      top: '-145px',
      [down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
        top: '-190px',
      },

      [up('md')]: {
        top: '-103px',
      },
      '@media(min-width: 600px) and (max-width: 667px)': {
        top: '-175px',
      },
    },
    categoriesTitle: {
      fontSize: '1.5rem',
      color: palette.common.white,
      margin: '0 1.5rem 0.5rem',
      composes: '$textShadow',
      [up('md')]: {
        margin: '0 0 0.375rem 0.25rem',
      },
    },
    categoryContainer: {
      padding: '0 0.25rem 1.25rem',
      [up('sm')]: {
        margin: '0 0.5rem',
        padding: '0',
      },
    },
    categoryWrapper: {
      height: 'auto',
      [up('sm')]: {
        minHeight: '120px',
        margin: '0.5rem 0',
      },
    },
    category: {
      textAlign: 'center',
      minHeight: '90px',
      border: `1px solid ${palette.grey[200]}`,
      transition: 'transform .5s',
      height: 'auto',

      [up('lg')]: {
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },

      [down('xs')]: {
        '&:first-of-type': {
          marginLeft: '12px',
        },

        '&:last-of-type': {
          marginRight: '12px',
        },
      },
    },
    categoryLink: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1.25rem 1rem',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    categoriesLink: {
      margin: '0 auto',
      color: palette.grey.A200,
      textDecoration: 'underline',
    },
    categoryIcon: {
      fontSize: '1.5rem',
      color: palette.primary.main,
      [up('sm')]: {
        fontSize: '1.75rem',
      },
    },
    categoryTitle: {
      fontSize: '0.875rem',
      color: palette.primary.main,
      hyphens: 'auto',
      '-ms-word-break': 'break-all',
      letterSpacing: '0.15px',
      fontWeight: typography.fontWeightSemiBold,
      lineHeight: '1.2rem',
      '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
        display: 'flex',
        justifyContent: 'center',
      },

      [up('sm')]: {
        fontSize: '1rem',
      },
    },
    categoriesCarouselContainer: {
      margin: 'auto',
      position: 'relative',
      width: '100%',
      '& .glider-contain': {
        height: 'auto',
      },
    },
    categoriesCarousel: {
      display: 'block',
      margin: '0 auto',
      overflow: 'hidden',
      [up('sm')]: {
        overflow: 'visible',
        maxWidth: '85%',
      },
      [up('md')]: {
        maxWidth: '92%',
      },
      '& .glider': {
        [up('md')]: {
          margin: '0 1rem',
        },
        [up('lg')]: {
          overflow: 'hidden',
        },
        '& .glider-track': {
          [down('xs')]: {
            width: '100%!important',
          },
        },
      },
    },
    categoriesSliderArrow: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'none',
      height: '1.5rem',
      padding: '0 0.63rem',
      [up('sm')]: {
        display: 'block',
      },
      '& svg': {
        fontSize: '0.75rem',
      },
      '&.prev-arrow-top': {
        left: 0,
        [up('lg')]: {
          left: '12px',
        },
      },
      '&.next-arrow-top': {
        right: 0,
        [up('lg')]: {
          right: '12px',
        },
      },
      '&.MuiIconButton-root.MuiButtonBase-root': {
        background: `${palette.primary.light}`,
        '& svg': {
          color: palette.primary.main,
        },
        '&:hover': {
          background: `${palette.primary.main}`,
          '& svg': {
            color: palette.primary.light,
          },
        },
      },
    },
    articlesCarousel: {
      overflow: 'hidden',
    },
    articlesPrevButton: { marginRight: '0.24rem' },
    articlesCarouselNavigation: {
      paddingBottom: '1.25rem',
      display: 'flex',
    },
    iconButton: {
      width: '2em',
      height: '1.5em',
      padding: 0,
      fontSize: 'initial',
      borderRadius: '0.25em',
      backgroundColor: palette.primary.main,
      border: 0,
      outline: 'none',
      color: palette.common.white,
      marginRight: '0.25rem',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s',
      '&:hover': {
        backgroundColor: palette.primary.dark,
      },
      '& svg': {
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
    articlesSlide: {
      marginRight: '0.5rem',
      height: 'auto',
      '&:last-child': {
        marginRight: '0',
      },
      [up('md')]: {
        marginRight: '1.5rem',
        cursor: 'default',
      },
    },
    boxesSection: {
      width: '100%',
      color: palette.common.white,
      background: palette.secondary.main,
    },
    boxesContainer: {
      composes: '$container',
      padding: '2rem 1.25rem 2.375rem',
      [up('md')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1.5rem',
      },
    },
    boxesItem: {
      display: 'flex',
      fontSize: '2.25rem',
      marginBottom: '2rem',
      alignItems: 'center',

      [up('md')]: {
        marginBottom: 0,
        marginRight: '1.5rem',
        width: '33.333%',
      },

      '&:last-child': {
        marginRight: 0,
        marginBottom: 0,
      },
    },
    boxesItemTitle: {
      marginLeft: '1.25rem',
    },
    imgMobile: {
      display: 'flex',
      justifyContent: 'center',
    },
    redirectionCta: {
      position: 'relative',
      display: 'block',
      margin: '4rem auto 4rem',
      textAlign: 'center',
      zIndex: 1,
      '@media(min-width: 375px)': {
        marginTop: '3rem',
      },
      [up('sm')]: {
        marginTop: '1.5rem',
      },
      [up('lg')]: {
        marginBottom: '5rem',
      },
      '@media(orientation: landscape)': {
        marginTop: '1.5rem',
      },
    },
    redirectionLink: {
      color: 'inherit',
      textDecoration: 'underline',
      transition: 'color 0.3s',
      '&:hover': {
        color: palette.grey[200],
      },
    },
    rightArrowContainer: {
      right: '-65px',
      position: 'absolute',
      width: '44px',
      height: '100%',
      top: 0,
    },
    leftArrowContainer: {
      left: '-55px',
      position: 'absolute',
      width: '44px',
      height: '100%',
      top: 0,
    },
    glide: {
      [up('sm')]: {
        marginLeft: '-10px',
      },
    },
    track: {
      overflow: 'hidden',
      width: '100%',
      [up('sm')]: {
        width: 'calc(100% + 20px)',
        paddingLeft: '10px',
        marginLeft: '-10px',
      },
    },
  }),
);

export default useStyles;
