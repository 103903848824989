import { createUseStyles } from '@pe-libs/react-components/build/theme';
import imgBackground from '#assets/images/expertDirectory/createLeadBackground.jpg';

const useStyles = createUseStyles(
  'expert-directory-create-lead',
  ({ palette: { primary, background, gradient, grey, error, success }, breakpoints: { up, down }, typography }) => ({
    main: {
      display: 'flex',
      overflow: 'hidden',
      [down('xs')]: {
        minHeight: 'calc(100vh - 8rem)',
      },
    },
    sideContainer: {
      position: 'relative',
      display: 'none',
      width: '13.5rem',
      overflow: 'hidden',
      background: `url(${imgBackground}) no-repeat`,
      backgroundSize: 'cover',

      [up('sm')]: {
        display: 'block',
      },

      [up('md')]: {
        width: '18.0625rem',
      },
    },
    gradientBlue: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: gradient.mask.primary,
      zIndex: 2,
    },
    gradientImage: {
      position: 'absolute',
      minHeight: '100%',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      backgroundSize: 'contain',
    },
    stepContainer: {
      marginBottom: '1rem',
      paddingTop: '0.2rem',

      [up('sm')]: {
        position: 'absolute',
        left: '1.5rem',
        top: '1.5rem',
        zIndex: 3,
        marginBottom: 0,

        '& .MuiChip-root': {
          background: `${background.paper} !important`,
        },
        '& .MuiChip-label': {
          color: primary.main,
        },
      },

      '& .MuiChip-label': {
        fontSize: '0.625rem',
      },
    },
    stepper: {
      position: 'absolute',
      top: '4.1rem',
      left: '1rem',
      zIndex: 9999,
      [up('sm')]: {
        top: '3rem',
        left: 0,
      },
    },
    logo: {
      position: 'absolute',
      left: '1.6875rem',
      bottom: '12px',
      zIndex: 3,

      [up('md')]: {
        left: '4rem',
      },
    },
    contentContainer: {
      flex: 1,
      minHeight: '33.886875rem',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    title: {
      marginBottom: '1rem',
    },

    text: {
      marginBottom: '2rem',
    },

    semiBold: {
      fontWeight: typography.fontWeightSemiBold,
    },
    required: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: '0.625rem',
      color: grey.A200,
      paddingTop: '0.2rem',
    },

    checkbox: {
      marginTop: '1rem',
    },

    buttons: {
      position: 'relative',
      paddingTop: '2.2rem',
      paddingBottom: '1.5rem',

      [up('sm')]: {
        right: '1.5rem',
        position: 'absolute',
        bottom: '1.5rem',
        padding: 0,
        background: 'none',

        '@media(max-height: 800px)': {
          position: 'relative',
          bottom: 0,
          paddingTop: '1rem',
          right: '-1.35rem',
        },
      },
    },
    successPageButtons: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: '0 1rem',

      [up('sm')]: {
        right: '1.5rem',
        bottom: '1.5rem',
      },
    },
    link: {
      color: grey.A200,
      cursor: 'pointer',
      fontSize: '0.875rem',
      lineHeight: '2.5rem',
    },
    successPage: {
      textAlign: 'center',
    },
    iconEnvelope: {
      height: '9.8rem',
      fontSize: '9rem',
      marginBottom: '1.5rem',
    },
    termsOfUse: {
      textAlign: 'right',
      padding: '1rem 0 0 0',
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      letterSpacing: '0.1px',
      color: grey[500],

      [up('sm')]: {
        padding: '6.6rem 0 0 0',
      },
    },
    termsOfUseDesc: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      letterSpacing: '0.1px',
      color: grey[700],
      paddingTop: '0.2rem',
    },
    autocomplete: {
      '& .MuiAutocomplete-listbox': {
        width: '100%',
        '& li': {
          minHeight: 'auto',
        },
      },
      '& > .selectContainer': {
        position: 'relative',
      },
    },
    locationAutocomplete: {
      position: 'relative',
    },
    autocompleteIcon: {
      fontSize: '1rem',
      color: grey[400],
      position: 'absolute',
      zIndex: 2,
      top: '1rem',
      left: '0.8em',
      '&.error': {
        color: error.main,
      },
      '&.success': {
        color: success.main,
      },
    },
    container: {
      height: '100%',
      padding: '3.6rem 1rem 3rem',
      [up('sm')]: {
        padding: '1.5rem 1rem 2rem',
      },
      [up('md')]: {
        padding: '1.5rem 1.5rem 2rem',
      },
    },
    successPageContainer: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      marginRight: '1.5rem',
      [down('sm')]: {
        marginRight: '1rem',
      },
      [down('xs')]: {
        width: '100%',
        margin: '0 1rem',
      },
    },
    listBox: {
      maxHeight: '30vh',
    },
    datePicker: {
      '& .calendar': {
        [down('xs')]: {
          height: '306px',
        },
        '& .rdrCalendarWrapper, .rdrMonthAndYearWrapper, .rdrMonth': {
          [down('xs')]: {
            width: '100%!important',
          },
        },
      },
    },
    keywords: {
      '& .MuiInputLabel-outlined': {
        maxWidth: '95%',
        lineHeight: 1.3,
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        maxWidth: '100%',
        lineHeight: 1,
      },
    },
  }),
);

export default useStyles;
