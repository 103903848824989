import { createUseStyles } from '@pe-libs/react-components/build/theme';

const useStyles = createUseStyles(
  'expert-directory-bottom-search-section',
  ({
    palette: {
      grey,
      common: { white },
      gradient,
    },
    breakpoints: {
      up,
      down,
      values: { lg },
    },
  }) => ({
    container: {
      backgroundImage: gradient.blue,
    },
    content: {
      padding: '3em',
      maxWidth: `${lg}px`,
      color: white,
      display: 'flex',
      flex: 1,
      margin: 'auto',
      [down('sm')]: {
        flexDirection: 'column-reverse',
      },
      [down('xs')]: {
        padding: '2.5em 1em',
      },
    },
    header: {
      [down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: '1.2',
      },
    },
    locationAutocomplete: {
      marginTop: '1rem',
      marginBottom: '1.2rem',
    },
    button: {
      [down('xs')]: {
        width: '100%',
      },
    },
    divider: {
      width: '1px',
      background: grey[200],
      margin: '0 3em',
      [down('sm')]: {
        height: '1px',
        width: '100%',
        margin: '2.5em 0',
      },
    },
    searchContent: {
      flex: '1 1 auto',
      [up('md')]: {
        flex: 1,
      },
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
      '& .MuiOutlinedInput-notchedOutline': {
        top: '-0.36em',
      },
    },
    ctaContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: '1 1 auto',
      [up('md')]: {
        flex: 1,
      },
    },
  }),
);

export default useStyles;
